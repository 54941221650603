import { pushEvent } from "@wbly/common";
import type { W, DataLayer } from "@wbly/common";

export const trackValuePropsCtaClick = (ctaLink: string) => {
  pushEvent(
    (window as W)?.dataLayer as DataLayer[],
    "",
    "value_props",
    "click_cta_button",
    `Redirected to ${ctaLink}`
  );
};
